import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Segment, Statistic } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';

// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpCode, setTopUpCode] = useState('');
  const [topUpCount, setTopUpCount] = useState(10);
  // const [minTopupCount, setMinTopUpCount] = useState(1);
  // const [amount, setAmount] = useState(0.0);
  const [minTopUp, setMinTopUp] = useState(1);
  const [topUpLink, setTopUpLink] = useState('');
  // const [enableOnlineTopUp, setEnableOnlineTopUp] = useState(false);
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [payWay, setPayWay] = useState('');
  // const account = useSelector((state) => state.account);
  // const navigate = useNavigate();

  const topUp = async () => {
    setTopUpCode();
    if (redemptionCode === '') {
      showInfo('请输入兑换码！')
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode
      });
      const { success, message, data } = res.data;
      if (success) {
        showSuccess('充值成功！');
        setUserQuota((quota) => {
          return quota + data;
        });
        setRedemptionCode('');
      } else {
        showError(message);
      }
    } catch (err) {
      showError('请求失败');
    } finally {
      setIsSubmitting(false); 
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError('超级管理员未设置充值链接！');
      return;
    }
    window.open(topUpLink, '_blank');
  };

  const preTopUp = async (payment) => {
    // if (!enableOnlineTopUp) {
    //     showError('管理员未开启在线充值！');
    //     return;
    // }
    // if (amount === 0) {
    //     await getAmount();
    // }
    if (topUpCount < minTopUp) {
        showInfo('充值数量不能小于' + minTopUp);
        return;
    }
    // setPayWay(payment)
    // setOpen(true);
    onlineTopUp(payment)
};

const onlineTopUp = async (payWay) => {
    // if (amount === 0) {
    //     await getAmount();
    // }
    if (topUpCount < minTopUp) {
        showInfo('充值数量不能小于' + minTopUp);
        return;
    }
    // setOpen(false);
    try {
        const res = await API.post('/api/user/pay', {
            amount: parseInt(topUpCount),
            top_up_code: topUpCode,
            payment_method: payWay
        });
        if (res !== undefined) {
            const {message, data} = res.data;
            // showInfo(message);
            if (message === 'success') {

                let params = data
                let url = res.data.url
                let form = document.createElement('form')
                form.action = url
                form.method = 'POST'
                // 判断是否为safari浏览器
                let isSafari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") < 1;
                if (!isSafari) {
                    form.target = '_blank'
                }
                for (let key in params) {
                    let input = document.createElement('input')
                    input.type = 'hidden'
                    input.name = key
                    input.value = params[key]
                    form.appendChild(input)
                }
                document.body.appendChild(form)
                form.submit()
                document.body.removeChild(form)
            } else {
                showError(data);
                // setTopUpCount(parseInt(res.data.count));
                // setAmount(parseInt(data));
            }
        } else {
            showError(res);
        }
    } catch (err) {
        console.log(err);
    } finally {
    }
};


const getUserQuota = async () => {
  let res = await API.get(`/api/user/self`);
  const { success, message, data } = res.data;
  if (success) {
    setUserQuota(data.quota);
  } else {
    showError(message);
  }
};

useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
        status = JSON.parse(status);
        if (status.top_up_link) {
            setTopUpLink(status.top_up_link);
        }
        if (status.min_topup) {
            setMinTopUp(status.min_topup);
        }
        // if (status.enable_online_topup) {
        //     setEnableOnlineTopUp(status.enable_online_topup);
        // }
    }
    getUserQuota().then();
}, []);

// const renderAmount = () => {
//     // console.log(amount);
//     return amount + '元';
// }

// const getAmount = async (value) => {
//     if (value === undefined) {
//         value = topUpCount;
//     }
//     try {
//         const res = await API.post('/api/user/amount', {
//             amount: parseFloat(value),
//             top_up_code: topUpCode
//         });
//         if (res !== undefined) {
//             const {message, data} = res.data;
//             // showInfo(message);
//             if (message === 'success') {
//                 setAmount(parseFloat(data));
//             } else {
//                 showError(data);
//                 // setTopUpCount(parseInt(res.data.count));
//                 // setAmount(parseInt(data));
//             }
//         } else {
//             showError(res);
//         }
//     } catch (err) {
//         console.log(err);
//     } finally {
//     }
// }

// const handleCancel = () => {
//     setOpen(false);
// }

  return (
    <Segment>
      <Header as='h3'>充值额度</Header>
      <Grid columns={2} stackable>
        <Grid.Column>
          <Form>
            <Form.Input
              placeholder='兑换码'
              name='redemptionCode'
              value={redemptionCode}
              onChange={(e) => {
                setRedemptionCode(e.target.value);
              }}
            />
            <Button color='green' onClick={openTopUpLink}>
              获取兑换码
            </Button>
            <Button color='yellow' onClick={topUp} disabled={isSubmitting}>
                {isSubmitting ? '兑换中...' : '兑换'}
            </Button>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <Statistic.Group widths='one'>
            <Statistic>
              <Statistic.Value>{renderQuota(userQuota)}</Statistic.Value>
              <Statistic.Label>剩余额度</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Grid.Column>
      </Grid>
      <Header as='h3'>在线充值</Header>
      <Grid columns={2} stackable>
        <Grid.Column>
          <Form>
            <Form.Input
              // placeholder='请输入充值金额最少1￥'
              // name='redemptionCode'
              // value={redemptionCode}
              // onChange={(e) => {
              //   setRedemptionCode(e.target.value);
              // }}
              field={'redemptionCount'}
              // label={'实付金额：' + topUpCount+ '￥'}
              placeholder={'充值数量，最少' + minTopUp + '￥'}
              name='redemptionCount'
              type='number'
              value={topUpCount}
              suffix={'￥'}
              min={minTopUp}
              // defaultValue={minTopUp}
              max={100000}
              onChange={async (e) => {
                  if (e.target.value < 1) {
                    e.target.value = 1;
                  }
                  if (e.target.value > 100000) {
                    e.target.value = 100000;
                  }
                  setTopUpCount(e.target.value);
                  // await getAmount(e.target.value);
              }}
              // onChange={ async (e) => { setTopUpCount(e.target.value) } }
            />
            <Button color='green' onClick={ async () => { preTopUp('zfb') } }>
            支付宝
            </Button>
            <Button color='yellow' onClick={ async () => { preTopUp('wx') } }>
            微信
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default TopUp;